import React from "react";
import "./style.scss";
import logo from "../../static/dc-logo.png";
const Placeholder = () => {
  return (
    <div className="placeholder--container">
      <div className="header">This website is under construction.</div>
      <div className="logo--container">
        <img className="logo" src={logo} alt="logo" />
      </div>

      <div className="follow">For more updates please follow us on</div>
      <div className="social--links">
        <a
          rel="noreferrer"
          href="https://www.instagram.com/dailycoder.in/"
          target="_blank"
        >
          Instagram
        </a>
        <a
          rel="noreferrer"
          href="https://www.youtube.com/@dailycoder"
          target="_blank"
        >
          Youtube
        </a>
      </div>
      <div className="thanks--message">Thanks for visiting us {":)"}</div>
    </div>
  );
};

export default Placeholder;
